import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-column-fluid" }
const _hoisted_2 = { class: "d-flex flex-column flex-column-fluid text-center p-10 py-lg-15" }
const _hoisted_3 = { class: "pt-lg-10 mb-10" }
const _hoisted_4 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/dashboard",
        class: "mb-10 pt-lg-10"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            alt: "Logo",
            src: "media/logos/logo-1.svg",
            class: "h-40px mb-5"
          }, null, -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "fw-bolder fs-4x text-gray-800 mb-10" }, "System Error", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "fw-bold fs-3 text-muted mb-15" }, [
          _createTextVNode(" Something went wrong! "),
          _createElementVNode("br"),
          _createTextVNode(" Please try again later. ")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: "/dashboard",
            class: "btn btn-lg btn-primary fw-bolder"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Go to homepage")
            ])),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", {
        class: "d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px",
        style: _normalizeStyle({
          backgroundImage: 'url(' + _ctx.getIllustrationsPath('17.png') + ')',
        })
      }, null, 4)
    ]),
    _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"d-flex flex-center flex-column-auto p-10\"><div class=\"d-flex align-items-center fw-bold fs-6\"><a href=\"https://keenthemes.com\" class=\"text-muted text-hover-primary px-2\">About</a><a href=\"mailto:support@keenthemes.com\" class=\"text-muted text-hover-primary px-2\">Contact</a><a href=\"https://1.envato.market/EA4JP\" class=\"text-muted text-hover-primary px-2\">Contact Us</a></div></div>", 1))
  ]))
}